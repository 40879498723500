import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';
import YouTubeEmbed from './youtube-embed';

function VideoMediaPreview({
  title,
  description,
  youTubeUrl,
  date,
  coverImage,
  alt,
}) {
  const re = /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=))([\w\-]{10,12})\b/;
  const embedId = youTubeUrl.match(re)[1];
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      borderRadius="xl"
      boxShadow="md"
      overflow="hidden"
      bgColor="white"
      cursor="pointer"
      transition="all .1s ease-out"
      _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
      onClick={onOpen}
    >
      <Box overflow="hidden" maxH="10rem" h="10rem" w="100%">
        <Img image={coverImage} alt={alt} />
      </Box>
      <Divider />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        p={6}
      >
        <Text
          textTransform="uppercase"
          color="gray.500"
          letterSpacing=".1rem"
          fontSize=".75rem"
        >
          {date}
        </Text>
        <Heading as="h4" fontSize="1.1rem" fontWeight="semibold" mb={3} mt={1}>
          {title}
        </Heading>
        <Text color="gray.600">{description}</Text>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={{ base: 4, md: 6 }}
          minWidth={{ base: '90%', lg: '60rem' }}
          mt="10rem"
          mx={{ base: 8, lg: 0 }}
        >
          <Text
            textTransform="uppercase"
            color="gray.500"
            letterSpacing=".1rem"
            fontWeight="semibold"
            fontSize=".75rem"
            ml={6}
            mt={4}
          >
            {date}
          </Text>
          <ModalHeader mt={-3}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <YouTubeEmbed embedId={embedId} />
          </ModalBody>

          <ModalFooter w="100%" justifyContent="flex-start">
            <Text textAlign="left">{description}</Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default VideoMediaPreview;
