import { Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

function AudioItem({ title, date, description, audioFile }) {
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
      mb={4}
    >
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        mb={4}
      >
        <Text fontWeight="semibold" fontSize="1.4rem" mb={1}>
          {title}
        </Text>
        <Text
          color="gray.500"
          fontSize="sm"
          fontWeight="semibold"
          mb={2}
          textTransform="uppercase"
          letterSpacing=".05rem"
        >
          {date}
        </Text>
        <Text color="gray.800">{description}</Text>
      </Flex>
      <ReactAudioPlayer src={audioFile} controls style={{ width: '100%' }} />
    </Flex>
  );
}

export default AudioItem;
