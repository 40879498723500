import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import AudioItem from './audio-item';

function AudioPlayer({ audioFiles }) {
  return (
    <Flex
      mt={8}
      w="100%"
      borderRadius="2xl"
      boxShadow="md"
      flexDir="column"
      bgColor="white"
      px={6}
      py={8}
    >
      <Stack w="100%" spacing="2rem">
        {audioFiles?.nodes?.map((audioFile) => (
          <AudioItem
            title={audioFile.title}
            description={audioFile.description.internal.content}
            date={audioFile.date}
            audioFile={audioFile.audioFile.file.url}
          />
        ))}
      </Stack>
    </Flex>
  );
}

export default AudioPlayer;
