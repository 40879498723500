import { Flex, Heading, Divider } from '@chakra-ui/react';
import React from 'react';

function SectionContainer({ heading, children = null }) {
  return (
    <Flex
      mt={16}
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
    >
      <Heading fontSize="1.7rem" fontWeight="semibold">
        {heading}
      </Heading>
      <Divider mt={5} />
      {children}
    </Flex>
  );
}

export default SectionContainer;
