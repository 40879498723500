import {
  Box,
  chakra,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import VideoMediaPreview from '../../components/media/video-media-preview';
import SEO from '../../components/seo';
import AudioPlayer from '../../components/media/audio-player';
import SectionContainer from '../../components/layout/section-containers';
import ArticlePreview from '../../components/media/article-preview';
import slugify from 'slugify';

function Media({
  data: { mediaVideos, header, printMedia, pageSeo, audioFiles },
}) {
  const seoTitle = pageSeo.edges[0].node.pageName;
  const seoDescription = pageSeo.edges[0].node.description.internal.content;
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            flexDir="column"
            justifyContent="space-between"
            w={{ base: '100%', lg: '50%' }}
            mr={20}
            order={{ base: 2, lg: 1 }}
            flexGrow={1}
          >
            <Flex flexDir="column" flexGrow={3}>
              <Heading as="h1" mt={10}>
                {header.heading}
              </Heading>
              <Text mt={3}>{header.introParagraph.internal.content}</Text>
            </Flex>
            <Flex
              mt={{ base: 8, lg: 28 }}
              py={6}
              px={8}
              borderRadius="lg"
              bgColor="secondary.100"
              w="100%"
              justify="center"
              align="center"
            >
              <Text
                color="secondary.500"
                fontWeight="semibold"
                textAlign="left"
              >
                For interviews and media requests call 1300 738 278 or{' '}
                <Link to="/contact">
                  <chakra.span
                    textDecoration="underline"
                    transition=".1s ease-in all"
                    _hover={{ color: 'secondary.600' }}
                  >
                    contact us
                  </chakra.span>
                </Link>
                .
              </Text>
            </Flex>
          </Flex>

          <Box
            w={{ base: '100%', lg: '50%' }}
            order={{ base: 1, lg: 2 }}
            overflow="hidden"
            boxShadow="lg"
            borderRadius="lg"
            mt={10}
            maxH={{ base: '15rem', md: '20rem', lg: '100%' }}
          >
            <Img
              image={getImage(header.headerImage.gatsbyImage)}
              alt={header.headerImage.title}
            />
          </Box>
        </Flex>
        <Flex
          mt={16}
          flexDir="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Heading fontSize="1.7rem" fontWeight="semibold">
            Television
          </Heading>
          <Divider mt={5} />
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={8}
            w="100%"
            mt={12}
          >
            {mediaVideos.edges.map((video) => (
              <VideoMediaPreview
                title={video.node.title}
                description={video.node.description}
                youTubeUrl={video.node.youTubeUrl}
                date={video.node.date}
                coverImage={getImage(video.node.coverImage.gatsbyImage)}
                alt={video.node.coverImage.title}
              />
            ))}
          </Grid>
        </Flex>
        <SectionContainer heading="Radio">
          <AudioPlayer audioFiles={audioFiles} />
        </SectionContainer>
        <SectionContainer heading="Print">
          <Grid
            mt={8}
            gap={6}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(4, 1fr)',
            }}
          >
            {printMedia.edges.map((article) => {
              const slug = `${slugify(
                article.node.heading
              ).toLowerCase()}-${slugify(
                article.node.publication
              ).toLowerCase()}`;
              return (
                <ArticlePreview
                  heading={article.node.heading}
                  date={article.node.datePublished}
                  publication={article.node.publication}
                  slug={slug}
                  alt={article.node.articleImage.title}
                />
              );
            })}
          </Grid>
        </SectionContainer>
      </Flex>
    </>
  );
}

export default Media;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "media" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "io7xBzm11ypqW5q37LXoX" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 900)
        }
      }
    }

    mediaVideos: allContentfulMediaVideo {
      edges {
        node {
          youTubeUrl
          description
          date(formatString: "MMMM, yyyy")
          title
          coverImage {
            title
            gatsbyImage(width: 300)
          }
        }
      }
    }

    printMedia: allContentfulPrintMedia {
      edges {
        node {
          heading
          datePublished(formatString: "Do MMMM, yyyy")
          publication
          articleImage {
            title
            gatsbyImage(width: 400)
          }
        }
      }
    }
    audioFiles: allContentfulRadioInterview {
      nodes {
        title
        description {
          internal {
            content
          }
        }
        audioFile {
          file {
            url
          }
        }
        date(formatString: "MMMM yyyy")
      }
    }
  }
`;
