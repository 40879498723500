import { Flex, Heading, Text, Box } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'gatsby';

function ArticlePreview({ heading, slug, date, publication, alt }) {
  return (
    <Flex
      w="100%"
      h="100%"
      transition=".1s ease-in all"
      boxShadow="md"
      _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
      cursor="pointer"
      p={4}
      bgColor="white"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.200"
    >
      <Link to={slug}>
        <Flex flexDir="column" w="100%" h="100%">
          <Text
            fontSize="xs"
            textTransform="uppercase"
            fontWeight="semibold"
            color="primary.500"
            letterSpacing={2}
            mb={1}
          >
            {publication}
          </Text>
          <Heading size="md">{heading}</Heading>
          <Text fontSize="xs" mt={2} color="gray.600">
            {date}
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
}

export default ArticlePreview;
