import { Flex, chakra } from '@chakra-ui/react';
import React from 'react';
import styled from '@emotion/styled';

interface Props {
  embedId: string;
}

const StyledIFrame = styled.iframe`
  width: 100%;
  min-height: 15rem;

  @media screen and (min-width: 768px) {
    min-height: 20rem;
  }

  @media screen and (min-width: 800px) {
    min-height: 30rem;
  }
`;

const YouTubeEmbed = ({ embedId }: Props) => (
  <Flex>
    <StyledIFrame
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Flex>
);

export default YouTubeEmbed;
